
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    title: { default: 'No Data Available' },
    text: { default: '' },
    image: {
      default: () => {
        return require('@/assets/new-design/images/no-review-submitted.png');
      },
    },
  },
});
