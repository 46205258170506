
import { defineComponent } from 'vue';
import { Tenant } from '@/models/TenantModel';
import maskUtil from '@/utils/mask';
import dates from '@/utils/dates';
import { searchValidation } from '@/views/new-design/validations/Search';
import useValidate from '@vuelidate/core';
import _ from 'lodash';
import toasts from '@/utils/toasts';

const initialValues = {
  first_name: '',
  last_name: '',
  date_of_birth: '',
} as unknown as Tenant;

export default defineComponent({
  name: 'SearchHeader',
  props: {
    loading: { default: false, type: Boolean },
  },
  data: () => ({
    v$: useValidate(),
    search: initialValues,
    errorsValue: [] as any,
  }),
  validations() {
    return searchValidation;
  },
  emits: ['search'],
  methods: {
    searchTenant() {
      this.v$.$validate();

      if (this.v$.$error) {
        let errors = [] as any;
        _.forEach(this.v$.search.$errors, (value, key) => {
          errors.push(value.$message);
        });

        const message = errors.join('<br/>');
        toasts.error({}, message);

        return false;
      }

      this.$emit('search', this.search);
    },

    clear() {
      this.search = {} as any;
    },
  },
  computed: {
    maskDate() {
      return maskUtil.date.aus;
    },
    dateFormat() {
      return dates.defaultFormat;
    },
  },
});
