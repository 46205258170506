import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100 empty-list-container d-flex flex-column align-items-center justify-content-center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "mt-2 mb-0" }
const _hoisted_4 = { class: "mb-0 text-normal pb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.image,
      alt: ""
    }, null, 8, _hoisted_2),
    _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.text), 1),
    _renderSlot(_ctx.$slots, "action")
  ]))
}