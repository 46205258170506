import { required, helpers } from '@vuelidate/validators';

export const searchValidation = {
  search: {
    first_name: {
      required: helpers.withMessage('First name is required.', required),
    },
    last_name: {
      required: helpers.withMessage('Surname is required.', required),
    },
    date_of_birth: {
      required: helpers.withMessage('Date of birth is required.', required),
    },
  },
};
